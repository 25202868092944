<template>
  <div>
    <b-modal
      ref="modalDataSeguimento"
      :title="$t('Seguimento: Reunião de pré-angariação')"
      :cancel-title="$t('Fechar')"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Efetuado')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <validation-observer
        #default="{ }"
        ref="formSaveValidatorSeguimento"
      >
        <b-form
          @submit.prevent
        >
          <b-container
            class="pt-0 pb-0 pl-0 pr-0"
            fluid
          >
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Seguimento')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <quill-editor
                      v-model="sw030s05"
                      :options="{
                        modules: {
                          toolbar: '#quill-toolbar-new',
                        },
                        placeholder: '',
                        bounds: '#quill-toolbar-new',
                      }"
                      class="quil-content border-bottom-0"
                    />
                    <div
                      id="quill-toolbar-new"
                      class="quill-toolbar d-flex justify-content-end border-top-0"
                    >
                      <button
                        v-b-tooltip.hover.top="$t('Negrito')"
                        class="ql-bold"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Itálico')"
                        class="ql-italic"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Sublinhado')"
                        class="ql-underline"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Lista')"
                        class="ql-list"
                        value="ordered"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Lista numerada')"
                        class="ql-list"
                        value="bullet"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Remover indentação')"
                        class="ql-indent"
                        value="-1"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Indentar')"
                        class="ql-indent"
                        value="+1"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Inserir link')"
                        class="ql-link"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Remover formatação')"
                        class="ql-clean"
                      />
                    </div>

                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-if="formVars.tipos.length > 0"
            >
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Tipo')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="sw030s13"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="formVars.tipos"
                      append-to-body
                      :calculate-position="withPopper"
                      :clearable="true"
                      :disabled="true"
                      label="sw090s02"
                      item-text="sw090s02"
                      item-value="sw090s01"
                    >
                      <template #option="{ sw090s02 }">
                        {{ sw090s02 }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Data do evento')"
                  >
                    <date-picker
                      v-model="sw030s04"
                      value-type="format"
                      :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                      type="date"
                      :editable="false"
                      :clearable="true"
                      :lang="langCalendar"
                      :append-to-body="true"
                      prefix-class="sw"
                    >
                      <template #icon-clear>
                        <v-select-deselect />
                      </template>
                    </date-picker>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              v-if="formVars.typeSend.length > 0"
            >
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Todo o dia ou hora?')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="sw030s18"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="formVars.typeSend"
                      append-to-body
                      :calculate-position="withPopper"
                      :clearable="true"
                      label="txt"
                      item-text="txt"
                      item-value="id"
                      @input="chooseAllDayOrHour"
                    >
                      <template #option="{ txt }">
                        {{ txt }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              v-if="sw030s04_time_show===true"
            >
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Hora do evento')"
                  >
                    <b-form-timepicker
                      v-model="sw030s04_time"
                      placeholder="00:00"
                      hide-header
                      now-button
                      :label-close-button="$t('Fechar')"
                      :label-now-button="$t('Hora atual')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

          </b-container>
        </b-form>
      </validation-observer>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          @click="cancel()"
        >
          {{ $t('Fechar') }}
        </b-button>
        <b-button
          variant="warning"
          @click="confirmSaveModal($event, true)"
        >
          {{ $t('Não Efetuado') }}
        </b-button>
        <b-button
          variant="primary"
          @click="confirmSaveModal($event, false)"
        >
          {{ $t('Efetuado') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BModal, BRow, BCol, BForm, BContainer, BFormGroup, VBTooltip, BFormTimepicker, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import modelSeguimentos from '@store-modules/crm/leads/seguimentos'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import checkNested from 'check-nested'
import { quillEditor } from '@/views/modules/components/editor-html'
import { mixFormGeral } from '../../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BContainer,
    BFormGroup,
    BFormTimepicker,
    BButton,
    vSelect,
    quillEditor,
    ValidationObserver,
    ValidationProvider,
    vSelectDeselect: vSelect.components.Deselect,
    DatePicker: oDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [mixFormGeral],
  props: {
    tipo: {
      type: String,
      default: () => '',
    },
    idLead: {
      type: String,
      default: () => '',
    },
    blockFieldTipo: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      langCalendar: {},

      sw030s05: null,

      // Evento normal
      sw030s13: null,
      sw030s04: null,
      sw030s04_time: null,
      sw030s04_time_show: false,
      sw030s18: null,
    }
  },
  computed: {
    ...mapGetters('seguimentos', ['formVars']),
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  async mounted() {
    await this.$store.dispatch('seguimentos/popupAddSeguimento', {
      keyReg: this.idLead || '',
      typeLead: '',
      tipo: this.tipo || '',
      blockFieldTipo: this.blockFieldTipo || '',
    }).then(async () => {
      await this.importLocale(this.$i18n.locale).then(res => {
        this.langCalendar = res.default
      })

      await this.fieldsDefault()
    }).catch(error => {
      this.hideModal()
      this.showMsgErrorRequest(error)
    })
  },
  methods: {
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async fieldsDefault() {
      this.sw030s13 = this.formVars.tipos.find(o => Number(o.sw090s01) === 6)
      this.sw030s18 = this.formVars.typeSend.find(o => Number(o.id) === 1)
      this.chooseAllDayOrHour()
    },
    chooseAllDayOrHour() {
      if ((this.sw030s18 !== null) && ('id' in this.sw030s18) && (Number(this.sw030s18.id) === 0)) {
        this.sw030s04_time = null
        this.sw030s04_time_show = true
      } else {
        this.sw030s04_time = null
        this.sw030s04_time_show = false
      }
    },
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataSeguimento.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataSeguimento.hide()
    },
    async confirmSaveModal(evt, force) {
      evt.preventDefault()
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorSeguimento.validate()
          .then(async valid => {
            if (valid) {
              const formDataSeguimento = new FormData()
              formDataSeguimento.append('sw030s03', this.idLead || '')

              if (checkNested(this.sw030s02, 'id') && (this.sw030s02.id !== '')) {
                formDataSeguimento.append('sw030s02', this.sw030s02.id)
              }

              formDataSeguimento.append('sw030s05', this.sw030s05 || '')

              formDataSeguimento.append('sw030s14', 0)

              if (checkNested(this.sw030s13, 'sw090s01') && (this.sw030s13.sw090s01 !== '')) {
                formDataSeguimento.append('sw030s13', this.sw030s13.sw090s01)
              }

              formDataSeguimento.append('sw030s04', this.sw030s04 || '')

              if ((this.sw030s18 !== null) && (this.sw030s18 !== undefined) && ('id' in this.sw030s18)) {
                formDataSeguimento.append('sw030s18', this.sw030s18.id)

                if (Number(this.sw030s18.id) === 0) {
                  formDataSeguimento.append('sw030s04_time', this.sw030s04_time || '')
                }
              }

              formDataSeguimento.append('seguimentoRapido', 1)
              if (force === true) {
                formDataSeguimento.append('sw030s09', 1)
              }

              self.$root.$emit('app::loading', true)
              this.$store.dispatch('seguimentos/saveSeguimento', formDataSeguimento).then(res => {
                self.$root.$emit('app::loading', false)
                self.showMsgSuccessRequest(res)
                this.resolvePromise(true)
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Tem de preencher os campos do seguimento')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Tem de preencher os campos do seguimento')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
  },
  setup() {
    const MODEL_SEGUIMENTOS = 'seguimentos'

    if (!store.hasModule(MODEL_SEGUIMENTOS)) {
      store.registerModule(MODEL_SEGUIMENTOS, modelSeguimentos)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SEGUIMENTOS)) store.unregisterModule(MODEL_SEGUIMENTOS)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space:normal!important;
}
</style>
